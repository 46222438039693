<template>
    <div id="servicos" class="col-sm-12 nopadding row margin-top-10px justify-content-center">
        <moreInformation></moreInformation>
        <div id="" class="col-10 row justify-content-center div-menu-images">
            <div id="divFirstLine" class="col-12 row justify-content-center divLines">
                <hexagonsProduct 
                    id="product-1"  
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleCargo"
                />
                <!-- eagleCargo -->
                <hexagonsProduct 
                    id="product-2"  
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleCity"
                />
                <hexagonsProduct 
                    id="product-3"  
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleColdManager"
                />
                <hexagonsProduct 
                    id="product-4"  
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleDriver"
                />
            </div>
            <div id="divSecondLine" class="col-12 row justify-content-center divLines">
                <hexagonsProduct 
                    id="product-5" 
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleFinder"
                />
                <hexagonsProduct 
                    id="product-6" 
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleFuel"
                />
                <!-- logo -->
                <hexagonsProduct 
                    id="product-7"  
                    class="produtos" 
                    product="logoEagle"
                />
                <!-- logo -->
                <hexagonsProduct 
                    id="product-8" 
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleJob"
                />
                <hexagonsProduct 
                    id="product-9" 
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eaglePerformance"
                />
            </div>
            <div id="divThirdLine" class="col-12 row justify-content-center divLines">
                <hexagonsProduct 
                    id="product-10"  
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleFinderPlus"
                />
                <hexagonsProduct 
                    id="product-11" 
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eaglePrevise"
                />
                <hexagonsProduct 
                    id="product-12" 
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleSensores"
                />
                <hexagonsProduct 
                    id="product-13" 
                    class="produtos" 
                    @openModal="openModalProduct" 
                    product="eagleWay"
                />
            </div>
        </div>
    </div>
</template>
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-100341043-1"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());  gtag('config', 'UA-100341043-1');
</script>
<script lang="js">
import Vue from 'vue'
import Swal from 'sweetalert2'
export default Vue.extend({
    components:{
        moreInformation: require('@/components/ComponentsPages/Servicos/moreInformation').default,
        hexagonsProduct: require('@/components/ComponentsPages/Servicos/hexagonsProduct').default,
    },
    async mounted(){
        if(Boolean(localStorage.getItem('cookies-accept'))){
            localStorage.setItem('Servicos', 'true')
        }
        const produtc1  = document.querySelectorAll('#product-1')
        const produtc2  = document.querySelectorAll('#product-2')
        const produtc3  = document.querySelectorAll('#product-3')
        const produtc4  = document.querySelectorAll('#product-4')
        const produtc5  = document.querySelectorAll('#product-5')
        const produtc6  = document.querySelectorAll('#product-6')
        const produtc7  = document.querySelectorAll('#product-7')
        const produtc8  = document.querySelectorAll('#product-8')
        const produtc9  = document.querySelectorAll('#product-9')
        const produtc10 = document.querySelectorAll('#product-10')
        const produtc11 = document.querySelectorAll('#product-11')
        const produtc12 = document.querySelectorAll('#product-12')
        const produtc13 = document.querySelectorAll('#product-13')
        var arrayProd = [ produtc1, produtc2, produtc3, produtc4, produtc5, produtc6, produtc7, produtc8, produtc9, produtc10, produtc11, produtc12, produtc13 ]
        var newArr = this.rand(arrayProd)
        for (const index in newArr){
            newArr[index].forEach(element => {
                element.style.animationPlayState = 'running'
            });
            await sleep(50);
        }
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    },
    methods:{
        rand(array) {
            var currentIndex = array.length, temporaryValue, randomIndex;
            while (0 !== currentIndex) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }
            return array;
        },
        openModalProduct(product){
            window.scroll(0, 1)
            Swal.fire({
                title: '',
                html: this.getTextForModulos(product),
                footer: '',
                confirmButtonText: 'Saiba mais...'
            }).then((result) => {
                if(result.value){
                    this.moreInformationProduct(product);
                }
            })
            if(Boolean(localStorage.getItem('cookies-accept'))){
                localStorage.setItem('Click_'+product, 'true')
            }
        },
        moreInformationProduct(product){
            var rotas = {
                'eagleCity': '/eaglecity',
                'eagleColdManager': '/eaglecoldmanager',
                'eagleDriver': '/eagledriver',
                'eagleFinder': '/eaglefinder',
                'eagleFuel': '/eaglefuel',
                'eagleJob': '/eaglejob',
                'eaglePerformance': '/eagleperformance',
                'eaglePrevise': '/eagleprevise',
                'eagleSensores': '/eaglesensores',
                'eagleWay': '/eagleway',
            }
            window.scroll(0, 1)
            this.$router.push(rotas[product]);
        },
        getTextForModulos(modulo){
            var textModules = {
                eagleJob         : "Você sabia que a hora extra pode ser um dos maiores custos para uma empresa? Com esta ferramenta é po...",
                eagleWay         : "Defina os destinos que a rota o Eagle Way determina para você! E ainda permite monitorar em tempo rea...",
                eagleCity        : "Com o Eagle City é possível controlar a operação do transporte urbano/coletivo. É focado diretamente ...",
                eagleFuel        : "O controle de combustível da frota é essencial para uma gestão adequada deste recurso. Ao longo do te...",
                eagleDriver      : "Uma boa utilização da frota significa também maior durabilidade dos veículos, menores custos com manu...",
                eagleFinder      : "Com o Eagle Finder é possível saber a localização exata dos veículos e motoristas. A partir da visual...",
                eaglePrevise     : "Saber exatamente quanto custa e os principais gastos relacionados à sua frota é essencial. O Eagle Pr...",
                eagleSensores    : "Com os sensores é possível ter um controle ainda maior da sua frota e da carga transportada. Os senso...",
                eaglePerformance : "O Eagle Performance utiliza a principal rede de comunicação do veículo para medir e extrair dados ess...",
                eagleColdManager : "Com o crescente número na produção de produtos que precisam preservar suas características em refrige...",
                eagleFinderPlus  : "Está precisando de mais controle? Parametrize pontos, regiões, áreas de riscos, personalize seus aler...", 
                eagleCargo       : "Dificuldade em controlar as rotas dos freteiros? Com o Eagle Cargo é possível ter controle total da o...",
            }
            window.scroll(0, 1)
            return textModules[modulo]
        }
    }
})
</script>
<style lang="scss" scoped>
    .produtos{
        animation: fade-in 1s cubic-bezier(.17,.67,.49,1.02);
        animation-play-state: paused;
        animation-fill-mode: forwards;
    }
    @keyframes fade-in {
        0% { 
            opacity: 0;
        }
        100% { 
            opacity: 1;
        }
    }
    .margin-top-10px{
        margin-top: 60px
    }
    #servicos{
        overflow: hidden;
        .div-menu-images{
            min-width:500px;
        }
        .divLines{
            position: relative;

            &#divSecondLine{
                bottom: 8%;
            }
            &#divThirdLine{
                bottom: 16%;
            }
            
        }
    }

    .swal2-modal{
        background-image: linear-gradient(0, #295860, #4d4e51) !important;
        .swal2-content{
            letter-spacing: 1;
            font-family: nexabook;
            text-align: center;
            color: #fff;
        }
        .swal2-confirm{
            font-family: nexabook;
            border-radius: 0px !important;
            background: #009dc9 !important;
        }
    }
</style>